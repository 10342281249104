import React, {useEffect, useState} from "react";
import {fs} from "../firebase";
import '../css/index.css';
import '../css/menu.css';
import Bar from "../elements/bar";
import {useTranslation} from "react-i18next";

function Menu() {
    const {t} = useTranslation();
    const [productQuantities, setProductQuantities] = useState(() => {
        const localData = localStorage.getItem("productQuantities");
        return localData ? JSON.parse(localData) : {};
    });
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedCompany, setSelectedCompany] = useState(localStorage.getItem("menu_status") || "All");
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null); // Changed to store the whole product
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("All");


    const handleCategorySelection = (category) => {
        setSelectedCategory(category);
    };


    const [isShownB, setIsShownB] = useState(false);
    const [PriceFilterType, setPriceFilterType] = useState('');


    const increaseQuantity = (productId) => {
        setProductQuantities(prevQuantities => ({
            ...prevQuantities,
            [productId]: (prevQuantities[productId] || 0) + 1
        }));
    };

    const decreaseQuantity = (productId) => {
        if (productQuantities[productId] > 1) {
            setProductQuantities(prevQuantities => ({
                ...prevQuantities,
                [productId]: prevQuantities[productId] - 1
            }));
        }
    };
    useEffect(() => {
        const storedProducts =
            JSON.parse(localStorage.getItem("selectedProducts")) || [];
        setSelectedProducts(storedProducts);
    }, []);
    // Сохраняем данные в localStorage при изменении selectedProducts
    useEffect(() => {
        localStorage.setItem("selectedProducts", JSON.stringify(selectedProducts));
    }, [selectedProducts]);
    useEffect(() => {
        localStorage.setItem(
            "productQuantities",
            JSON.stringify(productQuantities)
        );
    }, [productQuantities]);
    const handleBasketClick = (product) => {
        setSelectedProducts(prevSelectedProducts => {
            const isSelected = prevSelectedProducts.some(p => p.id === product.id);
            if (isSelected) {
                const updatedQuantities = {...productQuantities};
                delete updatedQuantities[product.id]; // Удаляем количество продукта из productQuantities при удалении из корзины
                setProductQuantities(updatedQuantities);
                return prevSelectedProducts.filter(p => p.id !== product.id);
            } else {
                return [...prevSelectedProducts, product];
            }
        });
    };
    const isProductSelected = (productId) => {
        return selectedProducts.some(p => p.id === productId);
    };


    const companies = ["All", "Aromiitaliani", "Copacabana", "Symphosium", "Poshnosh", "Nadimi", "Cave"];

    const [searchText, setSearchText] = useState('');

    const companyDescriptions = {
        Copacabana: "Copacabana is the place where the gastronomic cultures of Brazil and Japan meet and create the diverse tastes of fusion sushi, maki, cocktails and other specialties.",
        Aromiitaliani: "Aromi Italiani blends restaurant, cafe, pizzeria, and gelateria, offering Italian flavors, pizza, pasta, desserts, coffee, ice cream, croissants, and other delicacies on Europe Square.",
        Symphosium: "Symposium is a wine lounge bar and shop with over 1000 wine labels from Georgia and 15 countries, offering meats, cheeses, and fruit boards. Our sommelier assists guests.",
        Nadimi: "Nadimi is known for its exquisite dishes that blend traditional and modern culinary techniques, providing a unique dining experience with a diverse menu and excellent service.",
        Poshnosh: "Posh Nosh offers street food from around the world, including fish and chips, burgers, falafel, and burritos, providing diverse flavors at an affordable price.",
        Cave: "Underground club specializing in high-quality live and electronic music and signature cocktail history in collaboration with Astoria products. Also, a large selection of rare vinyl records will allow you to plunge headlong into high-quality sound"


    };

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const productList = [];

                    // Fetch all products
                    for (const company of companies.slice(1)) {
                        const snapshot = await fs.collection(company).get();
                        snapshot.forEach(doc => {
                            productList.push({ ...doc.data(), company });
                        });
                    }

                setProducts(shuffleArray(productList));
            } catch (error) {
                console.error("Error fetching products: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
        let IDA = window.location.href.split('/').at(-1);

        if (IDA === "copacabana") {
            setSelectedCompany("Copacabana")
        }
        if (IDA === "aromiitaliani") {
            setSelectedCompany("Aromiitaliani")
        }
        if (IDA === "symphosium") {
            setSelectedCompany("Symphosium")
        }
        if (IDA === "nadimi") {
            setSelectedCompany("Nadimi")
        }
        if (IDA === "poshnosh") {
            setSelectedCompany("Poshnosh")
        }
        if (IDA === "cave") {
            setSelectedCompany("Cave")
        }
    }, [selectedCategory]);

    useEffect(() => {
        if (PriceFilterType && products.length > 0) {
            const sortedProducts = [...products].sort((a, b) => {
                if (PriceFilterType === "Asc") {
                    return a.price - b.price;
                } else {
                    return b.price - a.price;
                }
            });
            // Only update the products if the sorted products are different from the current ones
            setProducts(sortedProducts);
        }
    }, [PriceFilterType]);
    const handleCompanySelection = (company) => {
        setSelectedCompany(company);
        setSearchText('');
        localStorage.setItem("menu_status", company);
        setSelectedCategory("All");
    };

    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const handleSearchInputChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleImageClick = (product) => { // Changed to take the whole product
        setSelectedProduct(product); // Set the selected product
        setModalOpen(true);
    };

    const closeModal = () => {
        setSelectedProduct(null);
        setModalOpen(false);
    };

    const companyCategories = {
        "Nadimi": ["Popular", "GeorgianKitchen", "Beverages"],
        "Poshnosh": ["Popular", "SandiwchesWraps", "TexMex", "BossBurgers", "Sides", "Sauces&Dips", "Drinks", "Shawarma", "Bakery"],
        "Copacabana": ["Popular", "Cozinha", "SushiBar", "Bar"],
        "Aromiitaliani": ["Popular", "Breakfast", "Caffetteria", "Trattoria", "Pizzeria", "Cantina"],
        "Symphosium": ["Popular", "Boards", "Georgianwine", "Brandy&Chacha", "Nonalcoholicdrinks"],
        "All": ["Popular", "GeorgianKitchen", "SandiwchesWraps", "TexMex", "BossBurgers", "Sides", "Beverages", "Sauces&Dips", "Drinks", "Shawarma", "Chickenshawrma", "Softdrinks", "Bakery", "Cozinha", "SushiBar", "Bar", "Breakfast", "Caffetteria", "Trattoria", "Pizzeria", "Cantina", "Boards", "Georgianwine", "Foreignwine", "Brandy&Chacha", "Nonalcoholicdrinks"],
        "Cave": ["Vinyl Store"],
    };


    const formattedCategories = {};
    Object.entries(companyCategories).forEach(([company, categories]) => {
        formattedCategories[company] = categories.map(category => {
            return category.replace(/([A-Z])/g, ' $1').trim();
        });
    });

    const filteredProducts = selectedCompany === "All"
        ? products.filter(product =>
            (selectedCategory === 'All' ||
                selectedCategory === 'Popular' && product.popular === true ||
                product.type === selectedCategory) &&
            (product.title?.toLowerCase().includes(searchText.toLowerCase()))


        )
        : products.filter(product =>
            product.company === selectedCompany &&
            (selectedCategory === 'All' ||
                selectedCategory === 'Popular' && product.popular === true ||
                product.type === selectedCategory) &&
            (product.title?.toLowerCase().includes(searchText.toLowerCase()))

        );

    const groupBySubcategory = (products) => {
        return products.reduce((groupedProducts, product) => {
            const subcategory = product.sub || "Others";
            if (!groupedProducts[subcategory]) {
                groupedProducts[subcategory] = [];
            }
            groupedProducts[subcategory].push(product);
            return groupedProducts;
        }, {});
    };
    const subcategoryOrder = [
        "Breakfast",
        "Pizzeria",
        "Trattoria",
        "Caffetteria",
        "Cantina",
        "Sandwiches Wraps",
        "Tex Mex",
        "Boss Burgers",
        "Sides",
        "Sauces Dips",
        "Shawarma",
        "Hot Dishes",
        "Khinkali",
        "Cold Dishes",
        "Kakhetian BBQ",
        "Bakery",
        "Garnish",
        "Bar",
        "Drinks",
        "Astoria",
        "Soft Drink",
        "Red wine",
        "White wine",
        "Beer",
        "Chacha",
        "Others"
    ];
    const togglePanelB = () => {
        setIsShownB(!isShownB);
    };
    useEffect(() => {
        if (isShownB) {
            const storedProducts = JSON.parse(localStorage.getItem('selectedProducts')) || [];
            setSelectedProducts(storedProducts);
        }
    }, [isShownB]);

    const handleRemoveClick = (productId) => {
        const updatedProducts = selectedProducts.filter(p => p.id !== productId);
        setSelectedProducts(updatedProducts);
        localStorage.setItem('selectedProducts', JSON.stringify(updatedProducts));
    };


    const [imageLoading, setImageLoading] = useState(false);

    useEffect(() => {
        setImageLoading(true);
    }, [selectedCompany, selectedCategory, searchText]);

    useEffect(() => {
        if (imageLoading) {
            const imageLoadPromises = filteredProducts.map(product => {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.src = product.imageUrl;
                    img.onload = resolve;
                    img.onerror = reject;
                });
            });
            Promise.all(imageLoadPromises)
                .then(() => setImageLoading(false))
                .catch(() => setImageLoading(false));
        }
    }, [imageLoading, filteredProducts]);


    const groupedProducts = groupBySubcategory(filteredProducts);

    const [activeGrids, setActiveGrids] = useState({});

    // Функция для изменения активности грида по индексу
    const toggleGridActive = (subcategory) => {
        setActiveGrids(prevState => ({
            ...prevState,
            [subcategory]: !prevState[subcategory] // Переключаем активность
        }));
    };


    return (
        <div className="">


            <div className={"auth"}>


                {(selectedProducts.length !== 0) && (
                    <div className="IconsB" onClick={togglePanelB}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                             fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                             strokeLinejoin="round"
                             className="icon icon-tabler icons-tabler-outline icon-tabler-basket">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>

                            <path
                                d="M5.001 8h13.999a2 2 0 0 1 1.977 2.304l-1.255 7.152a3 3 0 0 1 -2.966 2.544h-9.512a3 3 0 0 1 -2.965 -2.544l-1.255 -7.152a2 2 0 0 1 1.977 -2.304z"/>
                            <path d="M17 10l-2 -6"/>
                            <path d="M7 10l2 -6"/>
                        </svg>
                        <div className="basket_number">
                            {selectedProducts.length}
                        </div>
                    </div>
                )}


                {(isShownB && selectedProducts.length !== 0) && (
                    <div className="Panel">
                        <div className={"close"} onClick={togglePanelB}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round"
                                 className="icon icon-tabler icons-tabler-outline icon-tabler-x">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M18 6l-12 12"/>
                                <path d="M6 6l12 12"/>
                            </svg>
                            Close
                        </div>

                        <div className={"menu_items_containerB"}>
                            {selectedProducts.length > 0 ? (
                                selectedProducts.map((product, index) => {

                                const selectedLanguage = localStorage.getItem("selectedLanguage") || "en";

                                // Map of language codes to product descriptions
                                const descriptions = {
                                en: product.description, // Default description in English
                                ge: product.descriptionGE,
                                it: product.descriptionIT,
                                ar: product.descriptionAR,
                                tr: product.descriptionTR,
                                ru: product.descriptionRU,
                            };

                                // Get the description based on the selected language
                                const descriptionToDisplay = descriptions[selectedLanguage] || product.description; // Fallback to English if not found

                                return(
                                    <div key={product.id} className="product-card">
                                        <div className="menu_item_card_img">
                                            <img
                                                loading={"lazy"}
                                                src={product.imageUrl}
                                                alt={product.title}
                                                className="product-image"
                                                onClick={() => handleImageClick(product)}/>
                                        </div>


                                        <div className="product-info">


                                            <div className="product_title"
                                                 onClick={() => handleImageClick(product)}>{product.title}</div>
                                            <div className="product_description"
                                                 onClick={() => handleImageClick(product)}>{descriptionToDisplay}</div>

                                            <div className="quantity-controls">
                                                <button onClick={() => decreaseQuantity(product.id)}>-</button>
                                                <span>{productQuantities[product.id] || 1}</span>
                                                <button onClick={() => increaseQuantity(product.id)}>+</button>
                                            </div>

                                            <div className="product-price">
                                                <div className="product_price_text"
                                                     onClick={() => handleImageClick(product)}>
                                                    {(product.price * (productQuantities[product.id] || 1)).toFixed(2)}
                                                    <div className="price_lari">ლ</div>
                                                </div>


                                                <div className="basket" onClick={() => handleBasketClick(product)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24"
                                                         fill="none" stroke="currentColor" strokeWidth="2"
                                                         strokeLinecap="round"
                                                         strokeLinejoin="round"
                                                         className="iconsa icon-tabler icons-tabler-outline icon-tabler-basket">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <path d="M18 6l-12 12"/>
                                                        <path d="M6 6l12 12"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    )




                                })
                            ) : (
                                <p>No products added</p>
                            )}
                        </div>
                        <div className="total-price">
                            Total:
                            <div className={"price_num"}>
                                {selectedProducts.reduce((total, product) => {
                                    const quantity = productQuantities[product.id] || 1;
                                    return total + product.price * quantity;
                                }, 0).toFixed(2)}

                            </div> ლ
                        </div>
                    </div>
                )}


                <div className="button-group">
                    {companies.map(company => (
                        <button
                            key={company}
                            onClick={() => handleCompanySelection(company)}
                            className={`company-button ${selectedCompany === company ? 'active' : ''}`}
                        >
                            {company}
                        </button>
                    ))}
                </div>

                {selectedCompany !== "All" && (
                    <div className={`company_image ${selectedCompany.toLowerCase()}`}>
                        <div className={"company_text_description"}>
                            <div className={"company_text"}>
                                {selectedCompany}
                            </div>
                            <div className={"company_description"}>
                                {t(companyDescriptions[selectedCompany])}
                            </div>
                        </div>
                    </div>
                )}

                <div className="button-groupa">
                    {selectedCompany && companyCategories[selectedCompany] && (
                        <>
                            {companyCategories[selectedCompany].map(category => {
                                const formattedCategory = category.replace(/([A-Z])/g, ' $1').replace(/&/g, '');
                                return (
                                    <button
                                        key={category}
                                        onClick={() => handleCategorySelection(category)}
                                        className={`filter-button ${category} ${selectedCategory === category ? 'active' : ''}`}
                                    >
                                        {t(formattedCategory.trim())}
                                    </button>
                                );
                            })}
                        </>
                    )}
                </div>
                <div className={"dopFilterSearch"}>
                    <input
                        type="text"
                        value={searchText}
                        onChange={handleSearchInputChange}
                        placeholder={t('Search')}
                        className="search-input"
                    />
                </div>
                <div className={"dopFilter"}>

                    <select onChange={(e) => setPriceFilterType(e.target.value)}>
                        <option value={"Des"}>₾ {t('Descending price')}</option>
                        <option value={"Asc"}>₾ {t('Ascending price')}</option>
                    </select>
                </div>

                {loading && (
                    <div className="loading_ico">Loading...</div>
                )}

                <div className="menu_items_container">
                    {Object.entries(groupedProducts).length === 0 && products.length !== 0 ? (
                        <div className="no-products">Product not found

                        </div>
                    ) : (

                   Object.entries(groupedProducts)
                        .sort(([a], [b]) => {
                            // Sort based on the predefined order
                            const indexA = subcategoryOrder.indexOf(a);
                            const indexB = subcategoryOrder.indexOf(b);
                            if (indexA === -1 && indexB === -1) return 0;
                            if (indexA === -1) return 1;
                            if (indexB === -1) return -1;
                            return indexA - indexB;
                        })
                        .map(([subcategory, products]) => {
                            // Определить URL первого изображения из списка продуктов
                            const firstProductImage = products.length > 0 ? products[0].imageUrl : '';

                            return (
                                <div key={subcategory}
                                     className={`product-grid ${activeGrids[subcategory] ? 'active' : ''}`}
                                     id={`grd-${subcategory}`}>
                                    <div className="product_grid_category"
                                         style={{
                                             backgroundImage: `url(${firstProductImage})`,
                                             backgroundSize: 'cover',
                                             backgroundPosition: 'center',

                                         }}

                                         onClick={() => toggleGridActive(subcategory)}>

                                         <div className={"product_grid_content"}>
                                             {subcategory}
                                             <div className="arrow">
                                                 {activeGrids[subcategory] ? (
                                                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                          viewBox="0 0 24 24" fill="currentColor"
                                                          className="icon icon-tabler icons-tabler-filled icon-tabler-square-arrow-down">
                                                         <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                         <path
                                                             d="M19 2a3 3 0 0 1 3 3v14a3 3 0 0 1 -3 3h-14a3 3 0 0 1 -3 -3v-14a3 3 0 0 1 3 -3zm-7 5a1 1 0 0 0 -1 1v5.585l-2.293 -2.292l-.094 -.083a1 1 0 0 0 -1.32 1.497l4 4l.094 .083l.092 .064l.098 .052l.11 .044l.112 .03l.126 .017l.075 .003l.117 -.007l.149 -.029l.105 -.035l.113 -.054l.111 -.071a.939 .939 0 0 0 .112 -.097l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32l-.094 -.083a1 1 0 0 0 -1.32 .083l-2.293 2.292v-5.585l-.007 -.117a1 1 0 0 0 -.993 -.883z"/>
                                                     </svg>
                                                 ) : (
                                                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                          viewBox="0 0 24 24" fill="currentColor"
                                                          className="icon icon-tabler icons-tabler-filled icon-tabler-square-arrow-up">
                                                         <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                         <path
                                                             d="M19 2a3 3 0 0 1 3 3v14a3 3 0 0 1 -3 3h-14a3 3 0 0 1 -3 -3v-14a3 3 0 0 1 3 -3zm-7 5l-.09 .004l-.058 .007l-.118 .025l-.105 .035l-.113 .054l-.111 .071a1.008 1.008 0 0 0 -.112 .097l-4 4l-.083 .094a1 1 0 0 0 .083 1.32l.094 .083a1 1 0 0 0 1.32 -.083l2.293 -2.292v5.585l.007 .117a1 1 0 0 0 1.993 -.117v-5.585l2.293 2.292l.094 .083a1 1 0 0 0 1.32 -1.497l-4 -4l-.082 -.073l-.104 -.074l-.098 -.052l-.11 -.044l-.112 -.03l-.126 -.017l-.075 -.003z"/>
                                                     </svg>
                                                 )}
                                             </div>
                                         </div>
                                        <div className="background-overlay"></div>
                                    </div>
                                    {products.length > 0 && (
                                        products.map((product) => {
                                            const selectedLanguage = localStorage.getItem("selectedLanguage") || "en";

                                            // Map of language codes to product descriptions
                                            const descriptions = {
                                                en: product.description, // Default description in English
                                                ge: product.descriptionGE,
                                                it: product.descriptionIT,
                                                ar: product.descriptionAR,
                                                tr: product.descriptionTR,
                                                ru: product.descriptionRU,
                                            };
                                            const title = {
                                                en: product.title, // Default description in English
                                                ge: product.titleGE,
                                                it: product.titleIT,
                                                ar: product.titleAR,
                                                tr: product.titleTR,
                                                ru: product.titleRU,
                                            };

                                            // Get the description based on the selected language
                                            const descriptionToDisplay = descriptions[selectedLanguage] || product.description; // Fallback to English if not found
                                            const titleToDisplay = title[selectedLanguage] || product.title; // Fallback to English if not found



                                            return(

                                                <div key={product.id} className="product-card">
                                                    <div className="menu_item_card_img">
                                                        <img
                                                            loading="lazy"
                                                            src={product.imageUrl}
                                                            className="product-image"
                                                            onClick={() => handleImageClick(product)} // Pass the whole product
                                                        />
                                                    </div>
                                                    <div className="product-info">
                                                        {product.id}
                                                        <div className="product_title"
                                                             onClick={() => handleImageClick(product)}>{titleToDisplay}</div>
                                                        <div className="product_description"
                                                             onClick={() => handleImageClick(product)}>{descriptionToDisplay}</div>
                                                        <div className="product-price">
                                                            <div className="product_price_text"
                                                                 onClick={() => handleImageClick(product)}>
                                                                {product.price}
                                                                <div className="price_lari">ლ</div>
                                                            </div>
                                                            <div className="basket" onClick={() => handleBasketClick(product)}>
                                                                {isProductSelected(product.id) ? (
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                         viewBox="0 0 24 24" fill="none"
                                                                         stroke="currentColor" strokeWidth="2"
                                                                         strokeLinecap="round" strokeLinejoin="round"
                                                                         className="iconsa icon-tabler icons-tabler-outline icon-tabler-basket">
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                                        <path d="M18 6l-12 12"/>
                                                                        <path d="M6 6l12 12"/>
                                                                    </svg>
                                                                ) : (
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                         viewBox="0 0 24 24" fill="none"
                                                                         stroke="currentColor" strokeWidth="2"
                                                                         strokeLinecap="round" strokeLinejoin="round"
                                                                         className="icon icon-tabler icons-tabler-outline icon-tabler-basket">
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                                        <path
                                                                            d="M5.001 8h13.999a2 2 0 0 1 1.977 2.304l-1.255 7.152a3 3 0 0 1-2.966 2.544h-9.512a3 3 0 0 1-2.965-2.544l-1.255-7.152a2 2 0 0 1 1.977-2.304z"/>
                                                                        <path d="M17 10l-2-6"/>
                                                                        <path d="M7 10l2-6"/>
                                                                    </svg>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                )






                                    })
                                    )}
                                </div>
                            );
                        })

                        )}

                </div>
            </div>

            {modalOpen && selectedProduct && (() => {
                const selectedLanguage = localStorage.getItem("selectedLanguage") || "en";

                // Map of language codes to product descriptions
                const descriptions = {
                    en: selectedProduct.description, // Default description in English
                    ge: selectedProduct.descriptionGE,
                    it: selectedProduct.descriptionIT,
                    ar: selectedProduct.descriptionAR,
                    tr: selectedProduct.descriptionTR,
                    ru: selectedProduct.descriptionRU,
                };
                const title = {
                    en: selectedProduct.title, // Default description in English
                    ge: selectedProduct.titleGE,
                    it: selectedProduct.titleIT,
                    ar: selectedProduct.titleAR,
                    tr: selectedProduct.titleTR,
                    ru: selectedProduct.titleRU,
                };


                const titleToDisplay = title[selectedLanguage] || selectedProduct.title; // Fallback to English if not found
                // Get the description based on the selected language
                const descriptionToDisplay = descriptions[selectedLanguage] || selectedProduct.description; // Fallback to English if not found

                return (
                    <div className="modal-overlay" onClick={closeModal}>
                        <div className="modal" onClick={(e) => e.stopPropagation()}> {/* Prevent closing the modal when clicking inside */}
                            <span className="close" onClick={closeModal}>&times;</span>
                            <div className="modal-image">
                                <img src={selectedProduct.imageUrl} />
                            </div>
                            <div className="modal-title">{titleToDisplay}</div>
                            <div className="modal-description">{descriptionToDisplay}</div>
                            <div className="price_basket">
                                <div className="modal-price">{selectedProduct.price} ლ</div>
                            </div>
                        </div>
                    </div>
                );
            })()}
        </div>
    );
}

export default Menu;
