import React, {useEffect, useState, useRef} from "react";
import '../css/index.css';
import '../css/faq.css';

import '../css/home.css';
import {useTranslation} from "react-i18next";
import VIDEO from "../img/video2.mp4";
import Bar from "../elements/bar";
import {Link} from "react-router-dom";
import im1 from "../img/rooms/1.jpg";
import im2 from "../img/rooms/2.jpg";
import im3 from "../img/rooms/3.jpg";
import im4 from "../img/rooms/4.jpg";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {fs, storage} from "../firebase";
import 'react-datepicker/dist/react-datepicker.css';
import {useLocation} from 'react-router-dom';
import {doc, setDoc, getDoc, query, collection, where, getDocs, updateDoc} from "firebase/firestore";


function ORANGE({handleXBarItemChange}) {
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [name, setName] = useState(localStorage.getItem('USER_NAME'));
    const [surname, setSurname] = useState('');
    const [number, setNumber] = useState(localStorage.getItem('USER_NUMBER'));
    const [mail, setMail] = useState(localStorage.getItem('USER_POST'));

    const [errorMessage, setErrorMessage] = useState("");

    const [parallaxValue, setParallaxValue] = useState(0);
    const [dates, setDates] = useState([null, null]);
    const [startDate, endDate] = dates;

    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState('00:00');

    const [selectedTimePrice, setSelectedTimePrice] = useState(0);
    const [priceTime, setPriceTime] = useState(0);

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setDates([start, end]);
    };
    const {t} = useTranslation();

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const newParallaxValue = scrollTop * 0.5;
            setParallaxValue(newParallaxValue);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    const [productImages, setProductImages] = useState([im1,im2,im3,im4]);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showFullscreen, setShowFullscreen] = useState(false);


    const [timerState, setTimerState] = useState(true); // Изначальная задержка

    const calculateDaysBetween = (start, end) => {
        if (!start || !end) return 0;
        const timeDiff = end - start;
        return Math.ceil(timeDiff / (1000 * 60 * 60 * 24)) + 1;


    };

    const handleBooking = async () => {
        const bookingData = {


            dates: dates,
            mail,
            number,
            name,


        };


        const generateRandomId = () => {
            return Math.floor(100000 + Math.random() * 900000).toString();
        };

        try {


            const randomId = generateRandomId();
            await fs.collection("ORDERS").doc(bookingData.mail).collection("BOOKINGS").doc(randomId).set(bookingData);

            document.getElementById("nortific").classList.remove("active");
            document.getElementById("ORDER_C").classList.add("active");
            console.log("Booking successful with ID:", randomId);
            setIsFormVisible(true);
        } catch (error) {
            setIsFormVisible(true);
            console.error("Error creating booking:", error);
        }
    };


    const dayClassName = (date) => {
        if (!startDate || !endDate) return "";
        if (date >= startDate && date <= endDate) return "custom-range";
        if (date.getTime() === startDate.getTime() || date.getTime() === endDate.getTime()) return "custom-selected";
        return "";
    };

    useEffect(() => {
        const container = document.querySelector('.Product_images');
        let scrollAmount = container.scrollLeft;
        let scrollStep = 1; // Регулируйте этот параметр для изменения скорости
        let scrollDirection = 1; // 1 для вправо, -1 для влево
        let animationId = null;

        function scrollContent() {
            if (scrollDirection === 1 && scrollAmount >= container.scrollWidth - container.clientWidth) {
                scrollDirection = -1;
            } else if (scrollDirection === -1 && scrollAmount <= 0) {
                scrollDirection = 1;
            }

            scrollAmount += scrollStep * scrollDirection;
            container.scrollLeft = scrollAmount;

            animationId = requestAnimationFrame(scrollContent);
        }

        // Остановка анимации при ручном скроллинге, если timerState false
        function handleScroll() {
            if (!timerState) {
                cancelAnimationFrame(animationId);
            }
        }

        // Добавляем слушателя события скролла
        container.addEventListener('scroll', handleScroll);

        // Запуск анимации скролла, если timerState true
        if (timerState) {
            animationId = requestAnimationFrame(scrollContent);
        }

        // Очистка анимации при размонтировании компонента
        return () => {
            cancelAnimationFrame(animationId);
            container.removeEventListener('scroll', handleScroll);
        };

    }, [timerState]);
    const location = useLocation();
    const mainRef = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0); // Прокручиваем страницу вверх при изменении маршрута
        if (mainRef.current) {
            mainRef.current.scrollIntoView({behavior: 'smooth'}); // Прокручиваем до элемента .main
        }
    }, [location.pathname]); // Зависимость от изменения пути
    return (
        <div ref={mainRef} className="home_products">


           <Link to={"/"} onClick={() => {
                localStorage.setItem("activeItem", "5");
                handleXBarItemChange(5);
            }}  className={"products_conatiner_back_button"}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M15 6l-6 6l6 6"/>
                </svg>
                {t('Назад')}
            </Link>

            <div className={"description_text"}>
                <div className={"description_main_text"}>
                    Deluxe Double Room
                </div>
                <div className={"Product_images"} id={"PI"} onTouchStart={(e) => setTimerState(false)}
                     onMouseDown={(e) => setTimerState(false)}>

                    {productImages.length > 0 && (
                        productImages.map((imageUrl, index) => (
                            <div
                                key={index}
                                className={`images_container ${index === selectedImageIndex ? 'selected' : ''}`}
                                onClick={() => {
                                    setShowFullscreen(true);
                                    setCurrentIndex(index);
                                }}
                            >
                                <img src={imageUrl} alt={`Product Image ${index}`}/>
                            </div>
                        ))
                    )}


                </div>
                {showFullscreen && (
                    <div className="fullscreen-overlay">


                        <div className="fullscreen-image-container">
                            <div className="close-button" onClick={() => setShowFullscreen(false)}>

                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" strokeWidth="4" strokeLinecap="round"
                                     strokeLinejoin="round"
                                     className="">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M18 6l-12 12"/>
                                    <path d="M6 6l12 12"/>
                                </svg>

                            </div>

                            <img src={productImages[currentIndex]} alt={`Product Image ${currentIndex}`}/>
                            <div className="prev-button"
                                 onClick={() => setCurrentIndex((prevIndex) => (prevIndex - 1 + productImages.length) % productImages.length)}>

                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round"
                                     className="">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M15 6l-6 6l6 6"/>
                                </svg>
                            </div>
                            <div className="next-button"
                                 onClick={() => setCurrentIndex((prevIndex) => (prevIndex + 1) % productImages.length)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round"
                                     className="">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M9 6l6 6l-6 6"/>
                                </svg>
                            </div>
                        </div>

                    </div>
                )}


                <div className={"main_blocks_sec_text"}>
                    Featuring free toiletries and bathrobes, this double room includes a private bathroom with a shower, a hairdryer and slippers. The spacious air-conditioned double room features a flat-screen TV with cable channels, soundproof walls, a mini-bar, a tea and coffee maker as well as city views. The unit has 2 beds.
                </div>


                <div className={"nortific active"} id={"nortific"} onClick={() => setIsFormVisible(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg"
                         className="" width="24" height="24"
                         viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                         strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path
                            d="M11.5 17h-7.5a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6a2 2 0 1 1 4 0a7 7 0 0 1 4 6v3c.016 .129 .037 .256 .065 .382"/>
                        <path d="M9 17v1a3 3 0 0 0 2.502 2.959"/>
                        <path d="M15 19l2 2l4 -4"/>
                    </svg>
                    <h1>Your application is accepted

                    </h1>
                    <h2>Wait for the operator to call</h2>


                </div>


                {!isFormVisible && (

           <>

                    <div className={"date_calc"}>

                        <DatePicker
                            selected={startDate}
                            onChange={handleDateChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            className="purple"
                            inline
                            minDate={new Date()} // Ограничение на выбор прошедших дат
                            dayClassName={dayClassName}
                        />
                        {startDate && (
                            <div className={"dates"}>

                                {startDate && (
                                    <div className={"dates_text"}>
                                        {startDate && <div className={"date"}>
                                            <div className={"date_text_i"}>Check in</div>
                                            <div
                                                className={"date_i"}>{startDate.toLocaleDateString()}</div>
                                        </div>}


                                        {endDate && (
                                            <span className="dates_text_lines"></span>

                                        )
                                        }


                                        {startDate && endDate && (
                                            <div className={"days_count"}>

                                                <div className={"date_text_i"}>Total Days</div>
                                                {calculateDaysBetween(startDate, endDate)} day
                                            </div>
                                        )}


                                        {endDate && (
                                            <span className="dates_text_lines"></span>

                                        )
                                        }


                                        {endDate && <div className={"date"}>
                                            <div className={"date_text_i"}>Check out</div>
                                            <div
                                                className={"date_i"}>{endDate.toLocaleDateString()}</div>
                                        </div>}

                                    </div>
                                )}
                            </div>
                        )
                        }
                    </div>

                    <div className={"booking_inputs"}>

                        <div className={"NAME_INPUTS"}>
                            <div className={"ORDER_INPUTAS"}>
                                <div className={"nadstrok"}>{t('Name')}</div>
                                <input value={name} onInput={e => setName(e.target.value)}
                                       className={"ORDER_NUMBER_INPUTAS"} ></input>
                            </div>
                        </div>
                        <div className={"INDIVID_INPUTAS"}>
                            <div className={"nadstrok"}>{t('Number')}</div>
                            <input value={number} type={"number"}
                                   onInput={e => setNumber(e.target.value)}
                                   className={"ORDER_NUMBER_INPUTAS"} placeholder={"+995"}></input>

                        </div>
                        <div className={"INDIVID_INPUTAS"}>
                            <div className={"nadstrok"}>{t('Mail')}</div>
                            <input value={mail} placeholder={"@"} type={"mail"}
                                   onInput={e => setMail(e.target.value)}
                                   className={"ORDER_NUMBER_INPUTAS"}></input>

                        </div>
                        <button className="product_description_button" onClick={handleBooking}>Booking</button>
                        {errorMessage &&
                            <div className="product_error-message"> {t(errorMessage)}</div>}

                    </div>
                </>

                )}

            </div>





        </div>
    );
}

export default ORANGE;