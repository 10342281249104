import React, { useState, useEffect } from "react";
import '../css/index.css';
import '../css/productcrm.css';
import { fs } from '../firebase';
import {Link} from "react-router-dom"; // Make sure to import the Firestore instance from your firebase config

function MenuCrm() {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const productList = [];
                const companies = ["Copacabana", "Aromiitaliani", "Symphosium", "Nadimi", "Poshnosh"];
                for (const company of companies) {
                    const snapshot = await fs.collection(company).get();
                    snapshot.forEach(doc => {
                        productList.push({ ...doc.data(), id: doc.id, company });
                    });
                }
                setProducts(productList);
            } catch (error) {
                console.error("Error fetching products: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);

    const handleSearchInputChange = (event) => {
        setSearchText(event.target.value);
    };

    const filteredProducts = products.filter(product =>
        product.title.toLowerCase().includes(searchText.toLowerCase()) ||
        product.id.toLowerCase().includes(searchText.toLowerCase())
    );

    if (loading) {
        return <div className={"loading"}>Loading...</div>;
    }
    const handleDelete = async (productId, productCompany) => {
        if (window.confirm("Вы действительно хотите удалить объект?")) {
            try {
                // Delete the product document from Firestore
                await fs.collection(productCompany).doc(productId).delete();

                // Remove the product from the state
                setProducts(prevProducts => prevProducts.filter(product => product.id !== productId));

                // Optionally, you can delete the associated image from storage if you have the image URL
                // const storageRef = firebase.storage().refFromURL(imageUrl);
                // await storageRef.delete();
            } catch (error) {
                console.error("Error deleting product: ", error);
            }
        }
    };

    return (

      <div className={"crm_container"}>

          <div className="dopFilterSearch">
              <input
                  type="text"
                  value={searchText}
                  onChange={handleSearchInputChange}
                  placeholder="Search"
                  className="search-inputs"
              />
              <Link to={"/addd"} className={"add_item"}>
                  Add Product +
              </Link>
          </div>

          <div className="menu-crm">

              {filteredProducts.map(product => (

                  <div className={"product_crm"}>
                      <img className={"product_crm_card_img"} src={product.imageUrl}/>

                      <div  className="product_crm_card" key={product.id}>

                          <div className="product_crm_id">
                              {product.id} |   {product.company}
                          </div>
                          <div className="product_crm_title">
                              {product.title}
                          </div>
                          <div className="product_crm_description">
                              {product.description}
                          </div>
                          <div className="product_crm_price">
                              {product.price}₾
                          </div>
                      </div>
                      <div className={"crm_icons"}>
                          <Link className={"crm_icon"} key={product.id} to={`/edit-add/${product.company}/${product.id}`}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                   fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                   strokeLinejoin="round"
                                   className="icon icon-tabler icons-tabler-outline icon-tabler-pencil">
                                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                  <path d="M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4"/>
                                  <path d="M13.5 6.5l4 4"/>
                              </svg>
                          </Link>
                          <div className={"crm_icon"} onClick={() => handleDelete(product.id, product.company)}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                   fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                   strokeLinejoin="round"
                                   className="icon icon-tabler icons-tabler-outline icon-tabler-trash-x">
                                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                  <path d="M4 7h16"/>
                                  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"/>
                                  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"/>
                                  <path d="M10 12l4 4m0 -4l-4 4"/>
                              </svg>
                          </div>
                      </div>
                  </div>
              ))}
          </div>

      </div>
    );
}

export default MenuCrm;
