import React, {useState, useEffect} from "react";
import '../css/index.css';
import '../css/faq.css';

import '../css/home.css';
import {useTranslation} from "react-i18next";
import VIDEO from "../img/video2.mp4";
import Bar from "../elements/bar";
import {Link} from "react-router-dom";
import Xbar from "../elements/Xbar";

function Kinotel({ handleXBarItemChange }) {
    const [parallaxValue, setParallaxValue] = useState(0);
    const {t} = useTranslation();

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const newParallaxValue = scrollTop * 0.5;
            setParallaxValue(newParallaxValue);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className="main_blocks_container2" style={{transform: `translateY(${parallaxValue * 0.2}px)`}}>
            <div className={"kinote_head_container"}>
                <div className={"home_head_img"}>
                </div>
                <div className={"kinotel_head_text"}>
                    Kinotel in Batumi has 4-star accommodation with a terrace and a bar. The property is close to
                    The Neptun Fountain, Europe Square and Piazza. The accommodation features a 24-hour front desk,
                    airport transfers, room service and free WiFi.
                </div>
            </div>


            <div className={"main_blocks_text"}>
                Our rooms
            </div>


            <div className="main_blocks2">
                <Link to={"/"} onClick={() => {
                    localStorage.setItem("activeItem", "6");
                    handleXBarItemChange(6);
                }} className="main_block2 ru2" >

                    <div className={"main_block2_text"}>
                        №201
                    </div>
                </Link>
                <Link to={"/"} onClick={() => {
                    localStorage.setItem("activeItem", "6");
                    handleXBarItemChange(6);
                }}  className="main_block2 ru3" >
                    <div className={"main_block2_text"}>
                        №202
                    </div>
                </Link>
                <Link to={"/"} onClick={() => {
                    localStorage.setItem("activeItem", "6");
                    handleXBarItemChange(6);
                }}  className="main_block2 ru1">

                    <div className={"main_block2_text"}>
                        №203
                    </div>


                </Link>


                <Link to={"/"} onClick={() => {
                    localStorage.setItem("activeItem", "6");
                    handleXBarItemChange(6);
                }}  className="main_block2 ru4" >
                    <div className={"main_block2_text"}>
                        №204
                    </div>
                </Link>
                <Link to={"/"} onClick={() => {
                    localStorage.setItem("activeItem", "6");
                    handleXBarItemChange(6);
                }}  className="main_block2 ru5" >
                    <div className={"main_block2_text"}>
                        №205
                    </div>
                </Link>
     


                <div className={"block2_container2"}>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d512.0034097543872!2d41.635830720426426!3d41.65147087042387!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40678640f2500001%3A0x10bf640cb251c004!2sApartment%20Baratashvili%20street!5e0!3m2!1sru!2sge!4v1719711105958!5m2!1sru!2sge"
                        width="600" height="450"  allowFullScreen="" loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>



                <div className={"contact_us"}>
                    <div className={"contact_us_text"}>
                        Contact us
                    </div>
                    <div className={"contact_us_container"}>
                        <div className={"contact_us_container_main"}>
                            <input placeholder={"Email"}/>
                            <input placeholder={"Name"}/>
                        </div>
                        <div className={"contact_us_container_sec"}>
                            <textarea placeholder={"Enter your text here..."}/>

                        </div>
                        <button>Send</button>
                    </div>
                </div>


            </div>


        </div>

    );
                        }

                              export default Kinotel;