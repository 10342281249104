import React from "react";
import '../css/index.css';

function Delivery() {


    return (
        <div className="comingsoon">
            Coming Soon
        </div>
    );
}

export default Delivery;

