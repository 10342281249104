import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyCGEd76JsIeoNjefh8eseDE-m4fdMIwNJM",
    authDomain: "europe-fa766.firebaseapp.com",
    projectId: "europe-fa766",
    storageBucket: "europe-fa766.appspot.com",
    messagingSenderId: "328752709740",
    appId: "1:328752709740:web:27730db1544cf5e9909849",
    measurementId: "G-2C0Y649GQX"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Initialize services
const analytics = firebase.analytics();
const authentication = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();
const fs = firebase.firestore();
const storage = firebase.storage();

// Export services
export { firebase, analytics, authentication, provider, fs, storage };