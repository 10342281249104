import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

const resources = {
    ru: {
        translation: {
            "Welcome to a unique place - Europe Square. Five restaurants from different parts of the world are presented here, united into one concept, so that everyone can experience the atmosphere and taste of each country. You can also book a hotel and enjoy many activities and events.":
                "Добро пожаловать в уникальное место - Europe Square, Здесь представлены пять ресторанов из различных уголков мира объединенных в одну концепцию, чтобы каждый человек мог ощутить атмосферу и вкус каждой страны. Также вы можете забронировать отель и насладиться множеством мероприятий и ивентов."

            ,

            "By connecting to the loyalty system you become our regular customer. Using this system you will receive bonuses and access to private events.":
                "Подключившись к системе лояльности, вы становитесь нашим постоянным клиентом. Используя эту систему, вы будете получать бонусы и доступ к закрытым мероприятиям."

            ,
            "Loyalty program": "Программа лояльности"
            ,
            "Our brands": "Наши бренды"
            ,
            "Contact us": "Связаться с нами"

            ,
            "Popular": "Популярный",
            "Aromi Italiani blends restaurant, cafe, pizzeria, and gelateria, offering Italian flavors, pizza, pasta, desserts, coffee, ice cream, croissants, and other delicacies on Europe Square.":
                "Aromi Italiani объединяет ресторан, кафе, пиццерию и мороженое, предлагая итальянские блюда, пиццу, пасту, десерты, кофе, мороженое, круассаны и другие деликатесы на площади Европы."

            ,
            "Breakfast": "Завтрак",
            "Caffetteria": "Кафетерий",
            "Trattoria": "Траттория",
            "Pizzeria": "Пиццерия",
            "Cantina": "Кантина"

            ,

            "Copacabana is the place where the gastronomic cultures of Brazil and Japan meet and create the diverse tastes of fusion sushi, maki, cocktails and other specialties.":
                "Копакабана — это место, где встречаются гастрономические культуры Бразилии и Японии и создают разнообразные вкусы суши-фьюжн, маки, коктейлей и других деликатесов."

            ,
            "Cozinha": "Козинья",
            "Sushi Bar": "Суши Бар",
            "Bar": "Бар",


            "Symposium is a wine lounge bar and shop with over 1000 wine labels from Georgia and 15 countries, offering meats, cheeses, and fruit boards. Our sommelier assists guests.":
                "Symposium — это винный лаундж-бар и магазин с более чем 1000 наименований вин из Грузии и 15 стран, предлагающий мясные, сырные и фруктовые нарезки. Наш сомелье помогает гостям."

            ,

            "Boards": "Доски",
            "Georgianwine": "Грузинское вино",
            "Foreignwine": "Иностранное вино",
            "Brandy Chacha": "Бренди Чача",
            "Nonalcoholicdrinks": "Безалкогольные напитки"

,

            "Posh Nosh offers street food from around the world, including fish and chips, burgers, falafel, and burritos, providing diverse flavors at an affordable price.":
                "Posh Nosh предлагает уличную еду со всего мира, включая рыбу с жареным картофелем, гамбургеры, фалафель и буррито, предлагая разнообразные вкусы по доступной цене."

            ,

            "Shawarma": "Шаурма",
            "Sandiwches Wraps": "Сэндвичи",
            "Tex Mex": "Техас Мексика",
            "Boss Burgers": "Босс Бургеры",
            "Sides": "Стороны",
            "Sauces Dips": "Соусы",
            "Drinks": "Напитки",
            "Chickenshawrma": "Куриная шаурма",
            "Softdrinks": "Безалкогольные напитки",
            "Bakery": "Пекарня"


            ,

            "Nadimi is known for its exquisite dishes that blend traditional and modern culinary techniques, providing a unique dining experience with a diverse menu and excellent service.":
                "Надими известен своими изысканными блюдами, в которых сочетаются традиционные и современные кулинарные приемы, обеспечивая уникальные гастрономические впечатления с разнообразным меню и отличным обслуживанием."

,

            "Georgian Kitchen": "Грузинская кухня",
            "Beverages": "Напитки"


            ,
            "My orders": "Мои заказы",
            "Wallet and Payment System": "Кошелек и платежная система",
            "Change Profile Photo": "Изменить фотографию профиля",
            "Settings": "Настройки",
            "FAQ": "Часто задаваемые вопросы",
            "Logout": "Выйти"


,

            "What does Art Italy Group Ltd do?": "Чем занимается Art Italy Group Ltd?",
            "Art Italy Group Ltd was founded in 2016 by a group of professionals with a strong background in finance, real estate investment, and hospitality. Today, the companys main partners aim to promote and strengthen Georgian-Italian ties through various lifestyle concepts.": "Art Italy Group Ltd была основана в 2016 году группой профессионалов с большим опытом работы в сфере финансов, инвестиций в недвижимость и гостиничного бизнеса. Сегодня основные партнеры компании стремятся продвигать и укреплять грузино-итальянские связи посредством различных концепций образа жизни.",
            "Where is the headquarters of Art Italy Group Ltd located?": "Где находится штаб-квартира Art Italy Group Ltd?",
            "The headquarters is located in Europe Square, the cultural center of Batumi, where the company has been at the forefront of F&B development since 2019.": "Штаб-квартира расположена на площади Европы, культурном центре Батуми, где компания находится в F&B с 2019 года.",
            "What is the companys strategy?": "Какова стратегия компании?",
            "The companys strategy is to create a special atmosphere for guests by synthesizing employees, environment, tastes, location, experience, and diversity.": "Стратегия компании – создать особую атмосферу для гостей путем синтеза сотрудников, окружающей среды, вкусов, местоположения, опыта и разнообразия.",
            "What is the companys principle regarding team development?": "Каков принцип компании в отношении развития команды?",
            "One of the companys principles and beliefs is to assist in the development and strengthening of the team through continuous training and development of team members.": "Одним из принципов и убеждений компании является оказание помощи в развитии и укреплении команды посредством постоянного обучения и развития членов команды.",
            "How does the company maintain quality and innovation?": "Как компания поддерживает качество и инновации?",
            "The company attends international trade exhibitions such as SIGEP, Cibus & VinItaly to bring cutting-edge developments and new technologies back home.": "Компания посещает международные торговые выставки, такие как SIGEP, Cibus и VinItaly, чтобы привезти домой передовые разработки и новые технологии."


            ,
            "Email": "Электронная почта",
            "Name": "Имя",
            "Enter your text here...": "Введите ваш текст здесь...",
            "Send": "Отправить",
            "Read more": "Читать далее",
            "Password": "Пароль",
            "password": "пароль",
            "Login": "Войти",
            "Login with Google": "Вход через Google",
            "Dont have an account?": "Нет аккаунта?",
            "Create an account": "Создать аккаунт",
            "Login or Register": "Войти или зарегистрироваться",


            "To take advantage of all the possibilities": "Воспользуйтесь всеми возможностями",

            "Number": "Номер",
            "Descending price": "Цена по убыванию",
            "Ascending price": "Цена по возрастанию",

            "Search": "Поиск",


        },
    },

    it: {
        translation: {

            "Welcome to a unique place - Europe Square. Five restaurants from different parts of the world are presented here, united into one concept, so that everyone can experience the atmosphere and taste of each country. You can also book a hotel and enjoy many activities and events.":
                "Benvenuti in un luogo unico: Piazza Europa. Qui vengono presentati cinque ristoranti provenienti da diverse parti del mondo, uniti in un unico concetto, in modo che tutti possano sperimentare l'atmosfera e il gusto di ogni paese. Puoi anche prenotare un hotel e goderti numerose attività ed eventi."


            ,
            "Popular": "Popolare",
            "By connecting to the loyalty system you become our regular customer. Using this system you will receive bonuses and access to private events.":
                "Aiutandoti con il sistema leale, stabilisci il nostro cliente postale. Utilizzando questo sistema, potrai utilizzare i bonus e consegnarli alla borsa di sicurezza."

            ,
            "Loyalty program": "Programma di lealtà"

            ,
            "Our brands": "Markalarımız"


            ,
            "Contact us": "Contattaci"


            ,

            "Aromi Italiani blends restaurant, cafe, pizzeria, and gelateria, offering Italian flavors, pizza, pasta, desserts, coffee, ice cream, croissants, and other delicacies on Europe Square.":
                "Aromi Italiani unisce ristorante, bar, pizzeria e gelateria, offrendo sapori italiani, pizza, pasta, dolci, caffè, gelati, croissant e altre prelibatezze in Piazza Europa."

            ,
            "Breakfast": "Colazione",
            "Caffetteria": "Caffetteria",
            "Trattoria": "Trattoria",
            "Pizzeria": "Pizzeria",
            "Cantina": "Cantina"


            ,

            "Copacabana is the place where the gastronomic cultures of Brazil and Japan meet and create the diverse tastes of fusion sushi, maki, cocktails and other specialties.":
                "Copacabana è il luogo in cui le culture gastronomiche del Brasile e del Giappone si incontrano e creano i diversi gusti di sushi fusion, maki, cocktail e altre specialità."

            ,
            "Cozinha": "Cozinha",
            "Sushi Bar": "Sushi Bar",
            "Bar": "Sbarra"


            ,

            "Symposium is a wine lounge bar and shop with over 1000 wine labels from Georgia and 15 countries, offering meats, cheeses, and fruit boards. Our sommelier assists guests.":
                "Symposium è un wine lounge bar e un negozio con oltre 1000 etichette di vino provenienti dalla Georgia e da 15 paesi, che offre salumi, formaggi e taglieri di frutta. Il nostro sommelier assiste gli ospiti."

            ,

            "Boards": "Tavole",
            "Georgianwine": "Vino georgiano",
            "Foreignwine": "Vino straniero",
            "Brandy Chacha": "Brandy Chacha",
            "Nonalcoholicdrinks": "Bevande analcoliche"


            ,

            "Posh Nosh offers street food from around the world, including fish and chips, burgers, falafel, and burritos, providing diverse flavors at an affordable price.":
                "Posh Nosh offre cibo di strada da tutto il mondo, tra cui fish and chips, hamburger, falafel e burritos, offrendo sapori diversi a un prezzo accessibile."

            ,

            "Shawarma": "Shawarma",
            "Sandiwches Wraps": "Involucri di panini",
            "Tex Mex": "Tex Mex",
            "Boss Burgers": "Boss hamburger",
            "Sides": "Lati",
            "Sauces Dips": "Salse",
            "Drinks": "Bevande",
            "Chickenshawrma": "Chickenshawrma",
            "Softdrinks": "Bevande analcoliche",
            "Bakery": "Forno"


            ,

            "Nadimi is known for its exquisite dishes that blend traditional and modern culinary techniques, providing a unique dining experience with a diverse menu and excellent service.":
                "Nadimi è noto per i suoi piatti squisiti che fondono tecniche culinarie tradizionali e moderne, offrendo un'esperienza culinaria unica con un menu vario e un servizio eccellente."


,
            "Georgian Kitchen": "Cucina georgiana",
            "Beverages": "Bevande"

            ,
            "My orders": "I miei ordini",
            "Wallet and Payment System": "Portafoglio e sistema di pagamento",
            "Change Profile Photo": "Cambia foto del profilo",
            "Settings": "Impostazioni",
            "FAQ": "FAQ",
            "Logout": "Disconnettersi"
,
            "What does Art Italy Group Ltd do?": "Cosa fa Art Italy Group Ltd?",

            "Email": "Email",
            "Name": "Nome",
            "Enter your text here...": "Inserisci qui il tuo testo...",
            "Send": "Invia",
            "Read more": "Leggi di più",
            "Password": "Parola",
            "password": "parola",

            "Login": "Accesso",
            "Login with Google": "Accedi con Google",
            "Dont have an account?": "Non hai un account?",
            "Create an account": "Crea un account",
            "Login or Register": "Accedi o registrati",

            "To take advantage of all the possibilities": "Approfitta di tutte le possibilità",


            "Number": "Numero",

            "Descending price": "Prezzo decrescente",
            "Ascending price": "Prezzo crescente",

            "Search": "Cerca",


        },


    },

    tr: {
        translation: {

            "Welcome to a unique place - Europe Square. Five restaurants from different parts of the world are presented here, united into one concept, so that everyone can experience the atmosphere and taste of each country. You can also book a hotel and enjoy many activities and events.":
                "Eşsiz bir yere hoş geldiniz: Avrupa Meydanı. Her ülkenin atmosferini ve lezzetini deneyebilmesi için dünyanın farklı yerlerinden beş restoran tek bir konseptte bir araya getirilerek sunuluyor. Ayrıca otel rezervasyonu yapabilir ve birçok aktivite ve etkinliğin keyfini çıkarabilirsiniz."

            ,
            "Popular": "Popüler",
            "By connecting to the loyalty system you become our regular customer. Using this system you will receive bonuses and access to private events.":
                "Sadakat sistemine bağlanarak düzenli müşterimiz olursunuz. Bu sistemi kullanarak bonuslar alacak ve özel etkinliklere erişebileceksiniz."

            ,
            "Loyalty program": "Sadakat programı"
            ,
            "Our brands": "Ne yazık ki"
            ,
            "Contact us": "Bize Ulaşın"


            ,

            "Aromi Italiani blends restaurant, cafe, pizzeria, and gelateria, offering Italian flavors, pizza, pasta, desserts, coffee, ice cream, croissants, and other delicacies on Europe Square.":
                "Restoran, kafe, pizzacı ve gelaterileri harmanlayan Aromi Italiani, Avrupa Meydanı'nda İtalyan lezzetleri, pizza, makarna, tatlılar, kahve, dondurma, kruvasan ve diğer lezzetleri sunuyor."

            ,
            "Breakfast": "Kahvaltı",
            "Caffetteria": "Kafeterya",
            "Trattoria": "Trattoria",
            "Pizzeria": "Pizzacı",
            "Cantina": "Cantina"

            ,

            "Copacabana is the place where the gastronomic cultures of Brazil and Japan meet and create the diverse tastes of fusion sushi, maki, cocktails and other specialties.":
                "Copacabana, Brezilya ve Japonya'nın gastronomi kültürlerinin buluştuğu ve füzyon suşi, maki, kokteyller ve diğer spesiyalitelerin çeşitli lezzetlerini yarattığı yerdir."

            ,
            "Cozinha": "Cozinha",
            "Sushi Bar": "Suşi barı",
            "Bar": "Çubuk",


            "Symposium is a wine lounge bar and shop with over 1000 wine labels from Georgia and 15 countries, offering meats, cheeses, and fruit boards. Our sommelier assists guests.":
                "Symposium, Gürcistan'dan ve 15 ülkeden 1000'den fazla şarap markasının bulunduğu, et, peynir ve meyve tabağı sunan bir şarap lounge barı ve mağazasıdır. Sommelierimiz misafirlere yardımcı olmaktadır."

            ,

            "Boards": "Panolar",
            "Georgianwine": "Gürcü şarabı",
            "Foreignwine": "Yabancı şarap",
            "Brandy Chacha": "Brendi Chacha",
            "Nonalcoholicdrinks": "Alkolsüziçecekler"


            ,

            "Posh Nosh offers street food from around the world, including fish and chips, burgers, falafel, and burritos, providing diverse flavors at an affordable price.":
                "Posh Nosh, balık ve patates kızartması, hamburger, falafel ve burrito gibi dünyanın dört bir yanından sokak yemekleri sunarak çeşitli lezzetleri uygun fiyata sunuyor."

            ,

            "Shawarma": "Döner",
            "Sandiwches Wraps": "Sandviç Dürümleri",
            "Tex Mex": "Teksas Meksika",
            "Boss Burgers": "Patron Burgerleri",
            "Sides": "Taraflar",
            "Sauces Dips": "Soslar",
            "Drinks": "İçecekler",
            "Chickenshawrma": "Tavuk Şovrma",
            "Softdrinks": "Alkolsüz içecekler",
            "Bakery": "Fırın"


            ,

            "Nadimi is known for its exquisite dishes that blend traditional and modern culinary techniques, providing a unique dining experience with a diverse menu and excellent service.":
                "Nadimi, geleneksel ve modern mutfak tekniklerini harmanlayan, zengin menüsü ve mükemmel servisiyle benzersiz bir yemek deneyimi sunan enfes yemekleriyle tanınıyor."


,
            "Georgian Kitchen": "Gürcü Mutfağı",
            "Beverages": "İçecekler"
            ,
            "My orders": "Siparişlerim",
            "Wallet and Payment System": "Cüzdan ve Ödeme Sistemi",
            "Change Profile Photo": "Profil Fotoğrafını Değiştir",
            "Settings": "Ayarlar",
            "FAQ": "SSS",
            "Logout": "Çıkış Yap"
,
            "What does Art Italy Group Ltd do?": "Art Italy Group Ltd ne yapar?",
            "Art Italy Group Ltd was founded in 2016 by a group of professionals with a strong background in finance, real estate investment, and hospitality. Today, the companys main partners aim to promote and strengthen Georgian-Italian ties through various lifestyle concepts.": "Art Italy Group Ltd, 2016 yılında finans, gayrimenkul yatırımı ve konaklama alanlarında güçlü bir geçmişe sahip bir grup profesyonel tarafından kuruldu. Bugün şirketin ana ortakları, çeşitli yaşam tarzı konseptleri aracılığıyla Gürcistan-İtalyan bağlarını geliştirmeyi ve güçlendirmeyi amaçlıyor.",
            "Where is the headquarters of Art Italy Group Ltd located?": "Art Italy Group Ltd'nin genel merkezi nerededir?",
            "The headquarters is located in Europe Square, the cultural center of Batumi, where the company has been at the forefront of F&B development since 2019.": "Genel merkez, şirketin 2019'dan bu yana yiyecek ve içecek geliştirmede ön saflarda yer aldığı Batum'un kültür merkezi Avrupa Meydanı'nda bulunuyor.",
            "What is the companys strategy?": "Şirketin stratejisi nedir?",
            "The companys strategy is to create a special atmosphere for guests by synthesizing employees, environment, tastes, location, experience, and diversity.": "Şirketin stratejisi; çalışanlar, çevre, lezzetler, lokasyon, deneyim ve çeşitliliği sentezleyerek misafirler için özel bir atmosfer yaratmaktır.",
            "What is the companys principle regarding team development?": "Şirketin ekip gelişimi konusundaki prensibi nedir?",
            "One of the companys principles and beliefs is to assist in the development and strengthening of the team through continuous training and development of team members.": "Şirketin ilke ve inançlarından biri, ekip üyelerinin sürekli eğitimi ve gelişimi yoluyla ekibin gelişmesine ve güçlenmesine yardımcı olmaktır.",
            "How does the company maintain quality and innovation?": "Şirket kaliteyi ve yeniliği nasıl sürdürüyor?",
            "The company attends international trade exhibitions such as SIGEP, Cibus & VinItaly to bring cutting-edge developments and new technologies back home.": "Şirket, en son gelişmeleri ve yeni teknolojileri ülkesine getirmek için SIGEP, Cibus & VinItaly gibi uluslararası ticari fuarlara katılıyor."
,
            "Email": "E-posta",
            "Name": "İsim",
            "Enter your text here...": "Metninizi buraya girin...",
            "Send": "Gönder",
            "Read more": "Daha fazla oku",
            "Password": "Password",
            "password": "password",

            "Login": "Giriş",
            "Login with Google": "Google ile giriş yap",
            "Dont have an account?": "Hesabınız yok mu?",
            "Create an account": "Hesap oluştur",
            "Login or Register": "Giriş yap veya kayıt ol",

            "To take advantage of all the possibilities": "Tüm imkanlardan faydalanın",
            "Number": "Numara",


            "Descending price": "Azalan fiyat",
            "Ascending price": "Artan fiyat",

            "Search": "Arama",

        },
    },

    ar: {
        translation: {
            "Popular": "شائع",
            "Welcome to a unique place - Europe Square. Five restaurants from different parts of the world are presented here, united into one concept, so that everyone can experience the atmosphere and taste of each country. You can also book a hotel and enjoy many activities and events.":
                "مرحبًا بكم في مكان فريد من نوعه - ساحة أوروبا. يتم تقديم خمسة مطاعم من مختلف أنحاء العالم، متحدة في مفهوم واحد، حتى يتمكن الجميع من تجربة أجواء وطعم كل بلد. كما يمكنك حجز فندق والاستمتاع بالعديد من الأنشطة والفعاليات."


            ,

            "By connecting to the loyalty system you become our regular customer. Using this system you will receive bonuses and access to private events.":
                "من خلال الاتصال بنظام الولاء، تصبح عميلنا الدائم. باستخدام هذا النظام سوف تتلقى المكافآت والوصول إلى الأحداث الخاصة."

            ,
            "Loyalty program": "برنامج الولاء"
            ,
            "Our brands": "التخفيضات"

            ,
            "Contact us": "اتصل بنا"


            ,

            "Aromi Italiani blends restaurant, cafe, pizzeria, and gelateria, offering Italian flavors, pizza, pasta, desserts, coffee, ice cream, croissants, and other delicacies on Europe Square.":
                "يمزج مطعم أرومي إيتالياني بين مطعم ومقهى ومطعم بيتزا وجيلاتيريا، ويقدم النكهات الإيطالية والبيتزا والمعكرونة والحلويات والقهوة والآيس كريم والكرواسون وغيرها من الأطباق الشهية في ساحة أوروبا."

            ,
            "Breakfast": "إفطار",
            "Caffetteria": "كافتيريا",
            "Trattoria": "تراتوريا",
            "Pizzeria": "مطعم بيتزا",
            "Cantina": "كانتينا"


            ,

            "Copacabana is the place where the gastronomic cultures of Brazil and Japan meet and create the diverse tastes of fusion sushi, maki, cocktails and other specialties.":
                "كوباكابانا هي المكان الذي تلتقي فيه ثقافات تذوق الطعام في البرازيل واليابان وتخلق الأذواق المتنوعة للسوشي والماكي والكوكتيلات وغيرها من التخصصات."

            ,
            "Cozinha": "كوزينها",
            "Sushi Bar": "شريط السوشي",
            "Bar": "حاجِز",

            "Symposium is a wine lounge bar and shop with over 1000 wine labels from Georgia and 15 countries, offering meats, cheeses, and fruit boards. Our sommelier assists guests.":
                "Symposium هو بار صالة نبيذ ومتجر يضم أكثر من 1000 علامة نبيذ من جورجيا و15 دولة، ويقدم اللحوم والجبن وألواح الفاكهة. الساقي لدينا يساعد الضيوف."

            ,

            "Boards": "المجالس",
            "Georgianwine": "النبيذ الجورجي",
            "Foreignwine": "النبيذ الأجنبي",
            "Brandy Chacha": "براندي   تشاتشا",
            "Nonalcoholicdrinks": "مشروبات غير كحولية"


            ,

            "Posh Nosh offers street food from around the world, including fish and chips, burgers, falafel, and burritos, providing diverse flavors at an affordable price.":
                "يقدم مطعم Posh Nosh أطعمة الشوارع من جميع أنحاء العالم، بما في ذلك السمك والبطاطا والبرغر والفلافل والبوريتو، مما يوفر نكهات متنوعة بأسعار في متناول الجميع."

            ,

            "Shawarma": "شاورما",
            "Sandiwches Wraps": "لفائف السندويشات",
            "Tex Mex": "تكس مكس",
            "Boss Burgers": "بوس برجر",
            "Sides": "الجانبين",
            "Sauces Dips": "صلصات الانخفاضات",
            "Drinks": "مشروبات",
            "Chickenshawrma": "شاورما دجاج",
            "Softdrinks": "المشروبات الغازية",
            "Bakery": "مخبز"

            ,

            "Nadimi is known for its exquisite dishes that blend traditional and modern culinary techniques, providing a unique dining experience with a diverse menu and excellent service.":
                "يشتهر مطعم نديمي بأطباقه الرائعة التي تمزج بين تقنيات الطهي التقليدية والحديثة، مما يوفر تجربة طعام فريدة من نوعها مع قائمة متنوعة وخدمة ممتازة."


,
            "Georgian Kitchen": "المطبخ الجورجي",
            "Beverages": "المشروبات"
,
            "My orders": "طلباتي",
            "Wallet and Payment System": "المحفظة ونظام الدفع",
            "Change Profile Photo": "تغيير صورة الملف الشخصي",
            "Settings": "إعدادات",
            "FAQ": "التعليمات",
            "Logout": "تسجيل خروج"
,
            "What does Art Italy Group Ltd do?": "ماذا تفعل شركة Art Italy Group Ltd؟",
            "Art Italy Group Ltd was founded in 2016 by a group of professionals with a strong background in finance, real estate investment, and hospitality. Today, the companys main partners aim to promote and strengthen Georgian-Italian ties through various lifestyle concepts.": "تأسست شركة Art Italy Group Ltd في عام 2016 على يد مجموعة من المهنيين ذوي الخلفية القوية في مجال التمويل والاستثمار العقاري والضيافة. واليوم، يهدف الشركاء الرئيسيون للشركة إلى تعزيز وتقوية العلاقات الجورجية الإيطالية من خلال مفاهيم نمط الحياة المختلفة.",
            "Where is the headquarters of Art Italy Group Ltd located?": "أين يقع مقر Art Italy Group Ltd؟",
            "The headquarters is located in Europe Square, the cultural center of Batumi, where the company has been at the forefront of F&B development since 2019.": "يقع المقر الرئيسي في ساحة أوروبا، المركز الثقافي في باتومي، حيث كانت الشركة في طليعة تطوير المأكولات والمشروبات منذ عام 2019.",
            "What is the companys strategy?": "ما هي استراتيجية الشركة؟",
            "The companys strategy is to create a special atmosphere for guests by synthesizing employees, environment, tastes, location, experience, and diversity.": "تتمثل استراتيجية الشركة في خلق جو خاص للضيوف من خلال الجمع بين الموظفين والبيئة والأذواق والموقع والخبرة والتنوع.",
            "What is the companys principle regarding team development?": "ما هو مبدأ الشركة فيما يتعلق بتطوير الفريق؟",
            "One of the companys principles and beliefs is to assist in the development and strengthening of the team through continuous training and development of team members.": "من مبادئ ومعتقدات الشركة هو المساعدة في تطوير وتعزيز الفريق من خلال التدريب المستمر وتطوير أعضاء الفريق.",
            "How does the company maintain quality and innovation?": "كيف تحافظ الشركة على الجودة والابتكار؟",
            "The company attends international trade exhibitions such as SIGEP, Cibus & VinItaly to bring cutting-edge developments and new technologies back home.": "وتحضر الشركة المعارض التجارية الدولية مثل SIGEP وCibus وVinItaly لجلب التطورات المتطورة والتقنيات الجديدة إلى الوطن."

,

            "Email": "البريد الإلكتروني",
            "Name": "الاسم",
            "Enter your text here...": "أدخل نصك هنا...",
            "Send": "إرسال",
            "Read more": "اقرأ المزيد",
            "Password": "كلمة المرور",
            "password": "كلمة المرور",
            "Login": "تسجيل الدخول",

            "Login with Google": "تسجيل الدخول باستخدام Google",
            "Dont have an account?": "لا تمتلك حسابًا؟",
            "Create an account": "إنشاء حساب",
            "Login or Register": "تسجيل الدخول أو التسجيل",


            "To take advantage of all the possibilities": "الاستفادة من جميع الإمكانيات",
            "Number": "رقم",

            "Descending price": "السعر تنازليًا",
            "Ascending price": "السعر تصاعديًا",

            "Search": "بحث",

        },
    },


    ge: {
        translation: {

            "Welcome to a unique place - Europe Square. Five restaurants from different parts of the world are presented here, united into one concept, so that everyone can experience the atmosphere and taste of each country. You can also book a hotel and enjoy many activities and events.":
                "კეთილი იყოს თქვენი მობრძანება ევროპის მოედანზე, ამ უნიკალურ ადგილზე წარმოდგენილია ხუთი რესტორანი მსოფლიოს სხვადასხვა კუთხის სამზარეულოთი, რომელიც გაერთიანებულია ერთ კონცეფციაში, რადგან ყველა ადამიანს ჰქონდეს შესაძლებლობა შეიგრძნოს სხვადასხვა ქვეყნის სამზარეულოს არომატი და ატმოსფერო. ასევე შესაძლებელია დაჯავშნოთ სასტუმრო და ისარგებლოთ მრავალი აქტივობით და ღონისძიებით."


            ,
             "Popular":"პოპულარული",
            "By connecting to the loyalty system you become our regular customer. Using this system you will receive bonuses and access to private events.":
                "ლოიალობის სისტემასთან დაკავშირებით თქვენ ხდებით ჩვენი რეგულარული მომხმარებელი. ამ სისტემის გამოყენებით თქვენ მიიღებთ ბონუსებს და წვდომას პირად ღონისძიებებზე."

            ,
            "Loyalty program": "Ლოიალობის პროგრამა"

            ,
            "Our brands": "ჩვენი ბრენდები"


            ,
            "Contact us": "Დაგვიკავშირდით"


            ,

            "Aromi Italiani blends restaurant, cafe, pizzeria, and gelateria, offering Italian flavors, pizza, pasta, desserts, coffee, ice cream, croissants, and other delicacies on Europe Square.":
                "Aromi Italiani აერთიანებს რესტორანს, კაფეს, პიცერიას და გელატერიას, რომელიც გთავაზობთ იტალიურ არომატებს, პიცას, მაკარონის, დესერტების, ყავის, ნაყინის, კრუასანს და სხვა დელიკატესებს ევროპის მოედანზე."


            ,

            "Breakfast": "საუზმე",
            "Caffetteria": "კაფეტერია",
            "Trattoria": "ტრატორია",
            "Pizzeria": "პიცერია",
            "Cantina": "კანტინა"

            ,


            "Copacabana is the place where the gastronomic cultures of Brazil and Japan meet and create the diverse tastes of fusion sushi, maki, cocktails and other specialties.":
                "კოპაკაბანა არის ადგილი, სადაც ბრაზილიისა და იაპონიის გასტრონომიული კულტურები ხვდებიან და ქმნიან ფუჟენ სუშის, მაკის, კოქტეილების და სხვა სპეციალობების მრავალფეროვან გემოს."

            ,
            "Cozinha": "კოზინია",
            "Sushi Bar": "სუში ბარი",
            "Bar": "ბარი",

            "Symposium is a wine lounge bar and shop with over 1000 wine labels from Georgia and 15 countries, offering meats, cheeses, and fruit boards. Our sommelier assists guests.":
                "Symposium არის ღვინის ლაუნჯ-ბარი და მაღაზია 1000-ზე მეტი ღვინის ეტიკეტით საქართველოდან და 15 ქვეყნიდან, რომელიც გთავაზობთ ხორცს, ყველს და ხილის დაფებს. ჩვენი სომელიე სტუმრებს ეხმარება."

            ,

            "Boards": "დაფები",
            "Georgianwine": "ქართული ღვინო",
            "Foreignwine": "უცხოური",
            "Brandy Chacha": "კონიაკი ჭაჭა",
            "Nonalcoholicdrinks": "უალკოჰოლო სასმელები"



            ,

            "Posh Nosh offers street food from around the world, including fish and chips, burgers, falafel, and burritos, providing diverse flavors at an affordable price.":
                "Posh Nosh გთავაზობთ ქუჩის საკვებს მთელი მსოფლიოდან, მათ შორის თევზი და ჩიფსი, ბურგერები, ფალაფელი და ბურიტო, რაც გთავაზობთ მრავალფეროვან არომატს ხელმისაწვდომ ფასად."

            ,

            "Shawarma": "შაურმა",
            "Sandiwches Wraps": "სანდვიჩების სახვევები",
            "Tex Mex": "ტექს მექსი",
            "Boss Burgers": "ბოს ბურგერები",
            "Sides": "მხარეები",
            "Sauces Dips": "სოუსები",
            "Drinks": "სასმელები",
            "Chickenshawrma": "ქათმის შავრმა",
            "Softdrinks": "მსუბუქი სასმელები",
            "Bakery": "საცხობი"

            ,

            "Nadimi is known for its exquisite dishes that blend traditional and modern culinary techniques, providing a unique dining experience with a diverse menu and excellent service.":
                "ნადიმი ცნობილია თავისი დახვეწილი კერძებით, რომლებიც აერთიანებს ტრადიციულ და თანამედროვე კულინარიულ ტექნიკას, რაც უზრუნველყოფს უნიკალურ სასადილო გამოცდილებას მრავალფეროვანი მენიუთი და შესანიშნავი სერვისით."


            ,
            "Georgian Kitchen": "ქართული სამზარეულო",
            "Beverages": "Სასმელი"

            ,
            "My orders": "ჩემი შეკვეთები",
            "Wallet and Payment System": "საფულე და გადახდის სისტემა",
            "Change Profile Photo": "პროფილის ფოტოს შეცვლა",
            "Settings": "პარამეტრები",
            "FAQ": "FAQ",
            "Logout": "გამოსვლა"

            ,
            "What does Art Italy Group Ltd do?": "რას აკეთებს შპს Art Italy Group?",
            "Art Italy Group Ltd was founded in 2016 by a group of professionals with a strong background in finance, real estate investment, and hospitality. Today, the companys main partners aim to promote and strengthen Georgian-Italian ties through various lifestyle concepts.": "შპს Art Italy Group დაარსდა 2016 წელს პროფესიონალთა ჯგუფის მიერ ფინანსების, უძრავი ქონების ინვესტიციების და სტუმართმოყვარეობის სფეროში. დღეს კომპანიის მთავარი პარტნიორები მიზნად ისახავს ქართულ-იტალიური კავშირების პოპულარიზაციას და განმტკიცებას ცხოვრების სტილის სხვადასხვა კონცეფციის საშუალებით.",
            "Where is the headquarters of Art Italy Group Ltd located?": "სად მდებარეობს შპს Art Italy Group-ის შტაბ-ბინა?",
            "The headquarters is located in Europe Square, the cultural center of Batumi, where the company has been at the forefront of F&B development since 2019.": "სათაო ოფისი მდებარეობს ევროპის მოედანზე, ბათუმის კულტურულ ცენტრში, სადაც კომპანია 2019 წლიდან F&B განვითარების სათავეშია.",
            "What is the companys strategy?": "როგორია კომპანიის სტრატეგია?",
            "The companys strategy is to create a special atmosphere for guests by synthesizing employees, environment, tastes, location, experience, and diversity.": "კომპანიის სტრატეგიაა სტუმრებისთვის განსაკუთრებული ატმოსფეროს შექმნა თანამშრომლების, გარემოს, გემოვნების, მდებარეობის, გამოცდილებისა და მრავალფეროვნების სინთეზით.",
            "What is the companys principle regarding team development?": "როგორია კომპანიის პრინციპი გუნდის განვითარებასთან დაკავშირებით?",
            "One of the companys principles and beliefs is to assist in the development and strengthening of the team through continuous training and development of team members.": "კომპანიის ერთ-ერთი პრინციპი და რწმენაა გუნდის განვითარებასა და გაძლიერებაში დახმარება გუნდის წევრების უწყვეტი ტრენინგისა და განვითარების გზით.",
            "How does the company maintain quality and innovation?": "როგორ ინარჩუნებს კომპანია ხარისხს და ინოვაციებს?",
            "The company attends international trade exhibitions such as SIGEP, Cibus & VinItaly to bring cutting-edge developments and new technologies back home.": "კომპანია ესწრება საერთაშორისო სავაჭრო გამოფენებს, როგორიცაა SIGEP, Cibus & VinItaly, რათა დაბრუნდეს უახლესი განვითარება და ახალი ტექნოლოგიები სახლში."

            ,
            "Email": "ელფოსტა",
            "Mail": "ელფოსტა",
            "Name": "სახელი",
            "Enter your text here...": "აქ შეიყვანეთ თქვენი ტექსტი...",
            "Send": "გაგზავნა",
            "Read more": "მეტის წაკითხვა",
            "Password": "პაროლი",
            "password": "პაროლი",
            "Login": "შესვლა",
            "Login with Google": "Google-ით შესვლა",
            "Dont have an account?": "არ გაქვთ ანგარიში?",
            "Create an account": "შექმენით ანგარიში",
            "Login or Register": "შესვლა ან რეგისტრაცია",


            "To take advantage of all the possibilities": "გამოიყენეთ ყველა შესაძლოად გამოსაყენებელი მარაგი"
,
            "Number": "ნომერი",

            "Descending price": "ფასი კლებადი",
            "Ascending price": "ფასი ზრდადი",
            "Search": "ძიება"

        },
    },


};

i18n
    .use(initReactI18next)
    .init({
        lng: localStorage.getItem("selectedLanguage"), // язык по умолчанию
        resources,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;