import React from "react";
import '../css/index.css';

function Map() {


    return (
        <div className="main">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d341.7214691937015!2d41.63574445753216!3d41.65143579247761!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40678731d0b4010d%3A0xaf19d6eeb59276ff!2sAromi%20Italiani!5e0!3m2!1sru!2sge!4v1720488285530!5m2!1sru!2sge"
            allowFullScreen="" loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
    );
}

export default Map;