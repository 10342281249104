import React, { useState, useEffect } from "react";
import { fs } from "../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import '../css/buy.css';
function Buy() {
    const [aromaitalianiDB, setAromaitalianiDB] = useState(0);
    const [copacabanaDB, setCopacabanaDB] = useState(0);
    const [nadimiDB, setNadimiDB] = useState(0);
    const [poshnoshDB, setPoshnoshDB] = useState(0);
    const [symposiumDB, setSymposiumDB] = useState(0);

    const [aromaitalianiInput, setAromaitalianiInput] = useState(0);
    const [copacabanaInput, setCopacabanaInput] = useState(0);
    const [nadimiInput, setNadimiInput] = useState(0);
    const [poshnoshInput, setPoshnoshInput] = useState(0);
    const [symposiumInput, setSymposiumInput] = useState(0);

    useEffect(() => {
        async function fetchData() {
            const userDocRef = doc(fs, 'coins', 'user');
            const docSnap = await getDoc(userDocRef);
            const data = docSnap.data();
            setAromaitalianiDB(data.aromaitaliani);
            setCopacabanaDB(data.copacabana);
            setNadimiDB(data.nadimi);
            setPoshnoshDB(data.poshnosh);
            setSymposiumDB(data.symposium);
        }
        fetchData();
    }, []);

    const handleSave = async () => {
        const userDocRef = doc(fs, 'coins', 'user');
        const aromaitalianiNew = parseInt(aromaitalianiDB) + parseInt(aromaitalianiInput);
        const copacabanaNew = parseInt(copacabanaDB) + parseInt(copacabanaInput);
        const nadimiNew = parseInt(nadimiDB) + parseInt(nadimiInput);
        const poshnoshNew = parseInt(poshnoshDB) + parseInt(poshnoshInput);
        const symposiumNew = parseInt(symposiumDB) + parseInt(symposiumInput);

        await updateDoc(userDocRef, {
            aromaitaliani: aromaitalianiNew,
            copacabana: copacabanaNew,
            nadimi: nadimiNew,
            poshnosh: poshnoshNew,
            symposium: symposiumNew
        });

        setAromaitalianiDB(aromaitalianiNew);
        setCopacabanaDB(copacabanaNew);
        setNadimiDB(nadimiNew);
        setPoshnoshDB(poshnoshNew);
        setSymposiumDB(symposiumNew);

        setAromaitalianiInput(0);
        setCopacabanaInput(0);
        setNadimiInput(0);
        setPoshnoshInput(0);
        setSymposiumInput(0);
    };

    return (
        <div className="Buy">
          <div className={"Buy_container"}>
              <div className={"Buy_item"}>
                  <label>Aromaitaliani:</label>
                  <input type="number" value={aromaitalianiInput} onChange={(e) => setAromaitalianiInput(e.target.value)} />
              </div>
              <div className={"Buy_item"}>
                  <label>Copacabana:</label>
                  <input type="number" value={copacabanaInput} onChange={(e) => setCopacabanaInput(e.target.value)} />
              </div>
              <div className={"Buy_item"}>
                  <label>Nadimi:</label>
                  <input type="number" value={nadimiInput} onChange={(e) => setNadimiInput(e.target.value)} />
              </div>
              <div className={"Buy_item"}>
                  <label>Poshnosh:</label>
                  <input type="number" value={poshnoshInput} onChange={(e) => setPoshnoshInput(e.target.value)} />
              </div>
              <div className={"Buy_item"}>
                  <label>Symposium:</label>
                  <input type="number" value={symposiumInput} onChange={(e) => setSymposiumInput(e.target.value)} />
              </div>
              <button onClick={handleSave}>Сохранить</button>
          </div>
        </div>
    );
}

export default Buy;