import React, { useState } from "react";
import '../css/index.css';
import { fs } from "../firebase";

function Global() {
    const [sub, setSub] = useState('');
    const [company, setCompany] = useState('');
    const [documentNames, setDocumentNames] = useState('');

    const handleSaveProduct = async () => {
        try {
            // Проверяем, выбрана ли компания
            if (!company) {
                alert("Пожалуйста, выберите компанию.");
                return;
            }

            // Разделяем введенные названия документов по запятым и удаляем пробелы
            const documentNameArray = documentNames.split(',').map(name => name.trim());

            // Обновляем данные для каждого документа
            const updatePromises = documentNameArray.map(async (name) => {
                // Находим документ по названию (пример)
                const docRef = await fs.collection(company).where('id', '==', name).get();
                if (!docRef.empty) {
                    docRef.forEach(async (doc) => {
                        // Обновляем документ, добавляя поле 'sub'
                        await doc.ref.update({ sub });
                    });
                }
            });

            // Ждем завершения всех обновлений
            await Promise.all(updatePromises);

            alert("Продукт успешно обновлен!");

        } catch (error) {
            console.error("Ошибка при обновлении продукта: ", error);
            alert("Ошибка при обновлении продукта. Пожалуйста, попробуйте снова.");
        }
    };

    return (
        <div className="global_main">
            <select onChange={(e) => setCompany(e.target.value)}>
                <option value="" disabled selected>{"Компания"}</option>
                <option value="Nadimi">Nadimi</option>
                <option value="Copacabana">Copacabana</option>
                <option value="Aromiitaliani">Aromiitaliani</option>
                <option value="Poshnosh">Poshnosh</option>
                <option value="Symphosium">Symphosium</option>
                <option value="Jaydenshouse">Jaydenshouse</option>
            </select>

            <input className={"global_input"}
                placeholder={"Введите названия документов через запятую"}
                value={documentNames}
                onChange={(e) => setDocumentNames(e.target.value)}
            />

            <input className={"global_input"}
                placeholder={"Подкатегория"}
                value={sub}
                onChange={(e) => setSub(e.target.value)}
            />

            <button className="Add_btn" onClick={handleSaveProduct}>
                Сохранить изменения
            </button>
        </div>
    );
}

export default Global;