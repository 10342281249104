import React, {useState, useEffect} from "react";
import '../css/Xbar.css';
import {collection, getDocs} from "firebase/firestore";
import {fs} from "../firebase";
import {Link} from "react-router-dom";


function XBar({activeItem, onItemClick}) {


    const handleItemClick = (index) => {
        onItemClick(index);
    };

    useEffect(() => {
        localStorage.setItem("activeItem", activeItem);
    }, [activeItem]);

    const [totalUnreadCount, setTotalUnreadCount] = useState(0);



    const renderXBarItems = () => {
        const xbarItems = [
            (
                <svg xmlns="http://www.w3.org/2000/svg" className="icon"
                     width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                     fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M18 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                    <path d="M5 16v1a2 2 0 0 0 4 0v-5h-3a3 3 0 0 0 -3 3v1h10a6 6 0 0 1 5 -4v-5a2 2 0 0 0 -2 -2h-1" />
                    <path d="M6 9l3 0" />

                </svg>


            ),
            (<div className={"msg_containera"}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                         height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                         strokeLinecap="round" strokeLinejoin="round">


                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                        <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />

                    </svg>
                {totalUnreadCount !== "" && totalUnreadCount !== null && totalUnreadCount !== 0  && (
                    <div className={"msg_item"}>


                            {totalUnreadCount}

                    </div>
                )}
                </div>



            ),
            (
                <svg xmlns="http://www.w3.org/2000/svg" className="icon"
                     width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                     strokeLinecap="round" strokeLinejoin="round">
                    <path
                        d="M19,21.62H5a3,3,0,0,1-3-3V10.21A3,3,0,0,1,3.31,7.72l7-4.81a3,3,0,0,1,3.42,0l7,4.81A3,3,0,0,1,22,10.21V18.6A3,3,0,0,1,19,21.62Z"/>
                    <path className={"blade"} d="M7.39,17a15.56,15.56,0,0,1,9.5,0"/>
                    <rect width="24" height="24"/>

                </svg>

            ),

            (
                <div className={"SMS_ICO"}>
                    <div className={"circle active"} id={"CIRClE"}>

                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon"
                         width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                         fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <rect className="cls-1" width="24" height="24"/>
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M19 3v12h-5c-.023 -3.681 .184 -7.406 5 -12zm0 12v6h-1v-3m-10 -14v17m-3 -17v3a3 3 0 1 0 6 0v-3" />

                    </svg>




                </div>
            ),
            (<svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                  height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                  strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                    <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />

            </svg>




            )
        ];

        return xbarItems.map((item, index) => (
            <div
                key={index}
                className={`xbar_item ${index === activeItem ? "active" : ""}`}
                onClick={() => handleItemClick(index)}
            >

                    {item}

            </div>
        ));
    };
    return (
        <div className={"xbar"}>



            <div className={"xbar_container"}>
                <Link to={"/"} className={"xbar_items"}>
                    {renderXBarItems()}
                </Link>
            </div>
        </div>


    );
}

export default XBar;
